define('m01new/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var item = x3d.get('bauteile').findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;

      switch (variable) {
        case 'sparrenabstand':
          if (x3d.get('istFassade') === true) {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              y = -hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              z = 0;
            } else {
              x = -item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
              y = 0;
              z = 0;
            }
          } else {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              y = 0;
              z = 0;
            } else {
              x = -item.get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
              y = -hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
              z = 0;
            }
          }
          break;
        case 'breite':
          if (name === 'sparren2') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
                y = -hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
                z = 0;
              } else {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20) - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
                y = 0;
                z = 0;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20) - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
                y = 0;
                z = 0;
              } else {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
                y = -hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
                z = 0;
              }
            }
          } else if (name === 'grundlatte1') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
                y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
                z = 0;
              } else {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
                y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
                z = 0;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
                y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
                z = 0;
              } else {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
                y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
                z = 0;
              }
            }
          } else if (name === 'dachgeometrie') {
            x = -item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2 - 0.1;
            y = -item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3 - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
            z = 0;
          }
          break;
        case 'hoehe':
          if (name === 'sparren1') {
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
            if (bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
              z = -(bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 20);
            } else {
              z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }

            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
                z = z - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20) - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
                z = z - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20) - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              }
            }
          } else if (name === 'grundlatte1') {
            x = -(bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20);
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;

            if (bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial')) {
              z = -bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }

            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                z = z - 1.15 - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = x - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                z = z - 1.15 - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = x - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
              }
            }
          } else if (name.substring(0, 8) === 'daemmung') {
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
                z = z + hoeheMasskette + abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
                z = z + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              } else {
                x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              }
            }
          } else if (name === 'schalung') {
            x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 20;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            y = -0.3;

            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                z = z + hoeheMasskette + abstandVomBauteil - abstandVonDerMasslinie;
              } else {
                x = x + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                z = z + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              } else {
                x = x + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
              }
            }
          } else if (name === 'dachgeometrie') {
            x = -item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2 - item.get('gebaeudemasse').objectAt(0).get('giebelueberstand') - hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
            y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 6;
            z = 0;
            if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
              z = -item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;
            }
          }
          break;
        case 'laenge':
          x = 0;
          y = -item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3 - hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
          z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2;
          break;
        case 'giebelueberstand':
          x = -item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 2 - item.get('gebaeudemasse').objectAt(0).get('giebelueberstand') / 2;
          y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3;
          z = 0;
          if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
            y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3;
          } else if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
            var gebBreite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite');
            var giebelUeberstand = item.get('gebaeudemasse').objectAt(0).get('giebelueberstand');
            var dn1 = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
            var dn2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;

            y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - gebBreite / 2 * Math.tan(dn2) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3;
          }
          y = y + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie + 0.2;

          break;
        case 'traufeueberstand':
          x = 0;
          y = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3);
          z = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 + item.get('gebaeudemasse').objectAt(0).get('traufeueberstand') / 2;
          if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
            y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3;
          } else if (item.get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
            y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3;
          }
          y = y + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie + 0.2;
          break;
        case 'firstueberstand':
          x = 0;
          y = parseFloat(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')) + parseFloat(item.get('gebaeudemasse').objectAt(0).get('firstueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180) - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie + 0.2;
          z = -item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 2 - item.get('gebaeudemasse').objectAt(0).get('firstueberstand') / 2;
          break;
        case 'giebellaenge':
          x = 0;
          y = item.get('gebaeudemasse').objectAt(0).get('firsthoehe') - item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / 3 + hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie + 0.2;
          z = 0;
          break;
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      switch (variable) {
        case 'sparrenabstand':
          if (x3d.get('istFassade') === true) {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 1;
              omega = w180Grad;
            } else {
              x = 1;
              y = 1;
              z = 1;
              omega = w240Grad;
            }
          } else {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = 4.1888;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          }
          break;
        case 'breite':
          if (name === 'sparren2') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = 0;
                z = 1;
                omega = w180Grad;
              } else {
                x = 1;
                y = 1;
                z = 1;
                omega = w240Grad;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = -1;
                z = -1;
                omega = 4.1888;
              } else {
                x = 0;
                y = 1;
                z = 0;
                omega = -w90Grad;
              }
            }
          } else if (name === 'grundlatte1') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 0;
                y = 1;
                z = 0;
                omega = w90Grad;
              } else {
                x = -1;
                y = -1;
                z = -1;
                omega = -4.1888;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 0;
                y = 1;
                z = 0;
                omega = -w90Grad;
              } else {
                x = -1;
                y = -1;
                z = -1;
                omega = -4.1888;
              }
            }
          } else if (name === 'dachgeometrie') {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'hoehe':
          if (name === 'sparren1') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = -1;
                z = 1;
                omega = w240Grad;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = 1;
                z = -1;
                omega = 4.1888;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = w90Grad;
              }
            }
          } else if (name === 'grundlatte1') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = -1;
                z = 1;
                omega = w240Grad;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = 1;
                z = -1;
                omega = 4.1888;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = w90Grad;
              }
            }
          } else if (name.substring(0, 8) === 'daemmung') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = -1;
                z = 1;
                omega = w240Grad;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -1;
                y = 1;
                z = 1;
                omega = 4.1888;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            }
          } else if (name === 'schalung') {
            if (x3d.get('istFassade') === true) {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                x = 1;
                y = -1;
                z = 1;
                omega = w240Grad;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                x = -1;
                y = 1;
                z = 1;
                omega = 4.1888;
              } else {
                x = 0;
                y = 0;
                z = 1;
                omega = -w90Grad;
              }
            }
          } else if (name === 'dachgeometrie') {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'traufeueberstand':
          x = 0;
          y = 1;
          z = 0;
          omega = -w90Grad;
          break;
        case 'firstueberstand':
          x = 0;
          y = 1;
          z = 0;
          omega = -w90Grad;
          break;
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var variable = this.get('variable');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (variable) {
        case 'sparrenabstand':
          if (x3d.get('spabstandEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('sparrenabstand')).toFixed(1);
          }
          break;
        case 'breite':
          if (name === 'sparren2' && x3d.get('spbreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          } else if (name === 'grundlatte1' && x3d.get('glbreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          } else if (name === 'dachgeometrie' && x3d.get('gbreiteEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (name === 'sparren1' && x3d.get('sphoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (name === 'grundlatte1' && x3d.get('glhoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (name.substring(0, 8) === 'daemmung' && x3d.get('daemmstoffDickeEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (name === 'schalung' && x3d.get('schalungshoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (name === 'dachgeometrie' && x3d.get('firsthoeheEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('firsthoehe')).toFixed(1);
          }
          break;
        case 'giebelueberstand':
          if (x3d.get('uegiebelEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('giebelueberstand')).toFixed(1);
          }
          break;
        case 'laenge':
          if (x3d.get('glaengeEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge')).toFixed(1);
          }
          break;
        case 'traufeueberstand':
          if (x3d.get('uetraufeEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('traufeueberstand')).toFixed(1);
          }
          break;
        case 'firstueberstand':
          if (x3d.get('uefirstEingetragen')) {
            value = Number(item.get('gebaeudemasse').objectAt(0).get('firstueberstand')).toFixed(1);
          }
          break;
        case 'giebellaenge':
          var laenge = item.get('gebaeudemasse').objectAt(0).get('gebaeudelaenge');
          var dn1 = item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180;
          var dn2 = item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180;
          var breite = item.get('gebaeudemasse').objectAt(0).get('gebaeudebreite');

          var giebelHoehe = Number(breite) / 2 * Math.tan(dn2);
          var einzug = giebelHoehe / Math.tan(dn1);

          value = (Number(laenge) - 2 * einzug).toFixed(1);

          // console.log('dn1: '+ dn1 + ' ( '+ item.get('gebaeudemasse').objectAt(0).get('dachneigung') +' )');
          // console.log('dn2: '+ dn2 + ' ( '+ item.get('gebaeudemasse').objectAt(0).get('dachneigung2') +' )');

          // console.log('breite/2: '+ breite/2);

          // console.log('giebelHoehe: '+ giebelHoehe);
          // console.log('einzug: '+ einzug);
          // console.log(laenge +' - '+ einzug +' - '+ einzug +' = '+ (Number(laenge)-einzug-einzug));
          // console.log('value: '+value);

          break;
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('variable')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (name === 'lastenPfeil') {
        value = x3d.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.skalierungsfaktor'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('variable');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('sphoeheAktiv') === true && name === 'sparren1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('spbreiteAktiv') === true && name === 'sparren2' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('schalungshoeheAktiv') === true && name === 'schalung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('daemmstoffDickeAktiv') === true && name.substring(0, 7) === 'daemmung' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glhoeheAktiv') === true && name === 'grundlatte1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glbreiteAktiv') === true && name === 'grundlatte1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('spabstandAktiv') === true && name === 'dachgeometrie') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('firsthoeheAktiv') === true && name === 'dachgeometrie' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('dneigungAktiv') === true && name === 'dachgeometrie' && typ === 'dachneigung') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('dneigung2Aktiv') === true && name === 'dachgeometrie' && typ === 'dachneigung2') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('gbreiteAktiv') === true && name === 'dachgeometrie' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('glaengeAktiv') === true && name === 'dachgeometrie' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uegiebelAktiv') === true && name === 'dachgeometrie' && typ === 'giebelueberstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uetraufeAktiv') === true && name === 'dachgeometrie' && typ === 'traufeueberstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('uefirstAktiv') === true && name === 'dachgeometrie' && typ === 'firstueberstand') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.spbreiteAktiv', 'model.firstObject.sphoeheAktiv', 'model.firstObject.glbreiteAktiv', 'model.firstObject.glhoeheAktiv', 'model.firstObject.spabstandAktiv', 'model.firstObject.daemmstoffDickeAktiv', 'model.firstObject.schalungshoeheAktiv', 'model.firstObject.uefirstAktiv', 'model.firstObject.uetraufeAktiv', 'model.firstObject.uegiebelAktiv', 'model.firstObject.glaengeAktiv', 'model.firstObject.gbreiteAktiv', 'model.firstObject.dneigung2Aktiv', 'model.firstObject.dneigungAktiv', 'model.firstObject.firsthoeheAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    }

  });

  exports['default'] = x3dText;

});